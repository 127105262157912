define("discourse/plugins/discourse-bcc/discourse/components/bcc-checkbox", ["exports", "@ember/component", "@ember-decorators/component", "discourse/lib/decorators", "@ember/template-factory"], function (_exports, _component, _component2, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.bccAvailable}}
    <div class="bcc-checkbox">
      <label>
        <Input @type="checkbox" @checked={{this.checked}} />
        {{i18n "discourse_bcc.use_bcc"}}
      </label>
    </div>
  {{/if}}
  */
  {
    "id": "04NS3IXg",
    "block": "[[[41,[30,0,[\"bccAvailable\"]],[[[1,\"  \"],[10,0],[14,0,\"bcc-checkbox\"],[12],[1,\"\\n    \"],[10,\"label\"],[12],[1,\"\\n      \"],[8,[39,3],null,[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"checked\"]]]],null],[1,\"\\n      \"],[1,[28,[35,4],[\"discourse_bcc.use_bcc\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"div\",\"label\",\"input\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-bcc/discourse/components/bcc-checkbox.hbs",
    "isStrictMode": false
  });
  const BccCheckbox = dt7948.c(class BccCheckbox extends _component.default {
    bccAvailable() {
      return this.currentUser.staff && this.creatingPrivateMessage && ((this.targetRecipients || "").split(",").filter(Boolean).length > 1 || this.targetGroups);
    }
    static #_ = (() => dt7948.n(this.prototype, "bccAvailable", [(0, _decorators.default)("creatingPrivateMessage", "targetRecipients", "targetGroups")]))();
  }, [(0, _component2.tagName)("")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BccCheckbox);
});